import React, { Component } from 'react'
import { Link } from 'gatsby'
import Layout from '../../../layouts'
import Helmet from 'react-helmet'
import CtaRow from 'components/cta-row'

import config from '../../../config'
const { appUrl, signupRoute } = config

class MogelijkhedenPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: '',
    }

    this.setActive = this.setActive.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id,
    })
  }

  scrollToFAQSection(id) {
    const target = document.querySelector(`#${id}`)
    const scrollToTarget = target.offsetTop - 32
    window.scrollTo(0, scrollToTarget)
  }

  handleScroll() {
    const scrollPos = window.pageYOffset
    const cats = document.getElementById('categories').childNodes

    cats.forEach(item => {
      const target = item.dataset['target']
      const refElem = document.getElementById(target)
      const refTop = refElem.offsetTop - 120
      const refHeight = refElem.getBoundingClientRect().height + 128

      if (refTop <= scrollPos && refTop + refHeight > scrollPos) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })
  }

  render() {
    const { location } = this.props

    return (
      <Layout location={location}>
        <main className="koppelingen animated fadeInPage">
          <Helmet>
            <title>Overzicht van alle mogelijkheden | Employes</title>
            <meta
              name="description"
              content="Employes biedt met haar software een flink aantal mogelijkheiden om je salarisadministratie tip top op orde te krijgen. Bekijk de mogelijkheden van Employes."
            />

            <meta
              itemprop="name"
              content="Een overzicht van alle mogelijkheden van Employes"
            />

            <meta
              itemprop="description"
              content="Employes biedt met haar software een flink aantal mogelijkheiden om je salarisadministratie tip top op orde te krijgen. Bekijk de mogelijkheden van Employes."
            />

            <meta itemprop="image" content="/static/meta-mogelijkheden.jpg" />

            {/* OpenGraph tags */}
            <meta
              property="og:url"
              content="https://employes.nl/product/mogelijkheden/"
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Een overzicht van alle mogelijkheden van Employes"
            />
            <meta
              property="og:description"
              content="Employes biedt met haar software een flink aantal mogelijkheiden om je salarisadministratie tip top op orde te krijgen. Bekijk de mogelijkheden van Employes"
            />
            <meta
              property="og:image"
              content="https://employes.nl/static/meta-mogelijkheden.jpg"
            />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Een overzicht van alle mogelijkheden van Employes"
            />
            <meta
              name="twitter:description"
              content="Employes biedt met haar software een flink aantal mogelijkheiden om je salarisadministratie tip top op orde te krijgen. Bekijk de mogelijkheden van Employes"
            />
            <meta
              name="twitter:image"
              content="https://employes.nl/static/meta-mogelijkheden.jpg"
            />
          </Helmet>

          <header className="page-title padding-xl">
            <div className="container-md">
              <div className="grid yg center text-center">
                <div className="col-8 ">
                  <h1>De mogelijkheden van Employes</h1>
                  <p className="streamer large center">
                    De kracht van Employes zit in de eenvoud, maar dat wil niet
                    zeggen dat de functies beperkt zijn. Integendeel. De
                    software van employes voorziet in de behoeftes van startende
                    ondernemingen tot en met gevestigde bedrijven met een
                    uitgebreide personeelsadministratie.
                  </p>
                  <a
                    className="btn primary lg margin-m-top "
                    href={appUrl + signupRoute}
                    rel="nofollow"
                  >
                    Probeer nu gratis
                  </a>
                </div>
              </div>
            </div>
          </header>

          <section className="overview koppeling-overview padding-xxl">
            <div className="container-md  ">
              <div className="grid">
                <div className="col-3">
                  <p className="eyebrow">Categorieën</p>

                  <ul className="categories" id="categories">
                    <li
                      data-target="cat-1"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('cat-1')}
                    >
                      <h6>Salarisadministratie</h6>
                    </li>

                    <li
                      data-target="cat-2"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('cat-2')}
                    >
                      <h6>HR</h6>
                    </li>

                    <li
                      data-target="cat-3"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('cat-3')}
                    >
                      <h6>Verlof & verzuim</h6>
                    </li>

                    <li
                      data-target="cat-4"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('cat-4')}
                    >
                      <h6>Declaraties</h6>
                    </li>

                    <li
                      data-target="cat-5"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('cat-5')}
                    >
                      <h6>Rapporten</h6>
                    </li>

                    <li
                      data-target="cat-6"
                      className="category-item"
                      onClick={e => this.scrollToFAQSection('cat-6')}
                    >
                      <h6>Koppelingen</h6>
                    </li>
                  </ul>
                </div>

                <div className="col-9">
                  <div className="koppeling-section" id="cat-1">
                    <div className="grid align-middle">
                      <div className="col-12">
                        <p className="eyebrow">Direct online verlonen</p>

                        <h1 className="koppeling-heading-category">
                          Salarisadministratie
                        </h1>

                        <p className="koppeling-heading-description">
                          Alle belangrijke functies voor een goede
                          salarisadministratie. Van het automatisch berekenen
                          van de standaard loonheffingen tot het toepassen extra
                          toeslagen en vergoedingen.
                        </p>

                        <div className="grid">
                          <div className="col-4">
                            <strong>Alle typen dienstverbanden</strong>
                            <p>
                              Of je nou vaste krachten, oproepkrachten,
                              stagiairs of jezelf als DGA wilt verlonen.
                              Employes ondersteunt ieder type dienstverband.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Automatische loonaangifte</strong>
                            <p>
                              Alle loonheffingen worden automatisch berekend en
                              ingediend bij de belastingdienst.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Onbeperkt loonstroken maken</strong>
                            <p>
                              Het is mogelijk om onbeperkt wijzigingen aan te
                              brengen in een verloning, zonder dat hier extra
                              kosten voor in rekening worden gebracht.
                            </p>
                          </div>
                        </div>

                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>Wettelijk minimumloon</strong>
                            <p>
                              Employes zorgt er automatisch voor dat je niet
                              onder het wettelijk minimumloon kunt verlonen.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>CAO's</strong>
                            <p>
                              We ondersteunen een groot aantal CAO's waaronder voor de horeca,
                            detailhandel, metaal en kappers. <br/>
                              <Link to="/branches/" className="link inline">
                                Bekijk Branches
                              </Link>
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>WBSO</strong>
                            <p>
                              Het systeem ondersteunt de invoer van
                              afdrachtsverminderingen zoals de WBSO.
                            </p>
                          </div>
                        </div>

                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>Pensioen</strong>
                            <p>
                              Employes ondersteunt alle premie
                              pensioeninstellingen waaronder Brand New Day of
                              Nationale-Nederlanden en
                              bedrijfstakpensioenfondsen.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Toeslagen & vergoedingen</strong>
                            <p>
                              Voeg vaste toeslagen en vergoedingen toe bij de
                              werknemer of in een verloning. Bijvoorbeeld een
                              maaltijdenregeling, kilometervergoeding of extra
                              salaris.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Toeslaguren</strong>
                            <p>
                              We ondersteunen toeslaguren. Wanneer een
                              medewerker op bijvoorbeeld zon- of feestdagen
                              werkt, kun je een extra toeslag in procenten
                              toekennen.
                            </p>
                          </div>
                        </div>

                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>Slimme betaalbatches</strong>
                            <p>
                              Na het doen van een verloning worden er
                              betaalregels aangemaakt. Voeg deze regels toe in
                              een betaalbestand en bepaal zelf wanneer je welke
                              werknemer uitbetaalt.
                            </p>
                          </div>

                          <div className="col-4">
                            <strong>Bijtelling auto berekenen</strong>
                            <p>
                              Employes ondersteunt een automatische berekening
                              van de bijtelling auto van de zaak aan de hand van
                              een kenteken.{' '}
                              <strong>(Binnenkort beschikbaar)</strong>
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Reserveringen</strong>
                            <p>
                              De software ondersteunt de mogelijkheid om
                              reserveringen zoals een 13e maand en
                              tijd-voor-tijd vast te leggen.
                              <br />
                            </p>
                          </div>
                        </div>

                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>Jaaropgaves</strong>
                            <p>
                            Direct na afloop van het jaar kunnen werknemers de jaaropgave downloaden vanuit Employes.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Employee self-service</strong>
                            <p>
                            Via onze mobiele app die te vinden is in Apple store en Google Play store regelen je werknemers alles rondom werk.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="koppeling-section" id="cat-2">
                    <div className="grid">
                      <div className="col-12">
                        <p className="eyebrow">
                          Alles online. Alles op één plek.
                        </p>

                        <h1 className="koppeling-heading-category">
                          HR
                        </h1>
                        <p className="koppeling-heading-description">
                          Employes biedt je de volledige mogelijkheid om je
                          personeelsadministratie te voeren. Ook als je
                          HR-manager bent bij een groter bedrijf.
                        </p>

                        <div className="grid">
                          <div className="col-4">
                            <strong>Afdelingen</strong>
                            <p>
                              De software ondersteunt de mogelijkheid om
                              verschillende afdelingen te maken en werknemers
                              hierin onder te verdelen.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Bedrijfsfuncties</strong>
                            <p>
                              Voeg de verschillende bedrijfsfuncties toe in
                              Employes.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Documenten</strong>
                            <p>
                              Het is mogelijk om zowel bij het bedrijf als bij
                              werknemers documenten te uploaden en te delen.
                            </p>
                          </div>
                        </div>

                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>Rollenbeheer</strong>
                            <p>
                              Er zijn verschillende rollen waarin je Employes
                              kan gebruiken. Gebruik de software als Werkgever,
                              Manager, Werknemer of Boekhouder.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Notificaties</strong>
                            <p>
                              Op het dashboard en per mail ontvang je
                              herinneringen over bijvoorbeeld een aflopend
                              contract of een aanbiedingsbrief in verband met de
                              WAB.
                            </p>
                          </div>

                          <div className="col-4">
                            <strong>Notities</strong>
                            <p>
                              We ondersteunen de mogelijkheid om notities voor
                              werknemers toe te voegen.
                            </p>
                          </div>
                        </div>

                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>Templates voor contracten</strong>
                            <p>
                              Wij bieden modelcontracten aan die je kunt
                              gebruiken om vooraf ingevulde documenten te
                              genereren.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Verjaarden en jubilea</strong>
                            <p>
                              Op het dashboard krijg je meldingen over
                              verjaardagen en andere belangrijke datums.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Vrije velden</strong>
                            <p>
                              Bepaal zelf welke extra gegevens je wilt opslaan
                              in de personeelsadministratie.
                            </p>
                          </div>
                        </div>


                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>Workflows</strong>
                            <p>
                              In Employes kun je takenlijsten definiëren om workflows vast te leggen zodat je geen enkele stap binnen je administratie vergeet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="koppeling-section" id="cat-3">
                    <div className="grid">
                      <div className="col-12">
                        <p className="eyebrow">Eenvoudig online bijhouden</p>

                        <h1 className="koppeling-heading-category">Verlof & verzuim</h1>

                        <p className="koppeling-heading-description">
                          Met Employes kun je eenvoudig verlof en verzuim
                          bijhouden. Het wordt daarna automatisch verwerkt in de
                          salarisadministratie. Daardoor hoef je niet met
                          verschillende systemen te werken.
                        </p>

                        <div className="grid">

                          <div className="col-4">
                            <strong>Vakantiedagen</strong>
                            <p>
                              Employes biedt uitgebreide mogelijkheiden tot het
                              instellen en bijhouden van de vakantie-uren.
                            </p>
                          </div>

                          <div className="col-4">
                            <strong>Feestdagen</strong>
                            <p>
                              Employes houdt automatisch rekening met feestdagen. Je kunt zelf instellen welke dagen je werknemers vrij zijn.
                            </p>
                          </div>

                          <div className="col-4">
                            <strong>Onbetaald verlof</strong>
                            <p>
                              Employes biedt de mogelijkheid om onbetaald verlof vast te leggen zodat het juiste bruto bedrag wordt ingehouden.
                            </p>
                          </div>

                        </div>

                        <div className="grid margin-m-top">

                          <div className="col-4">
                            <strong>Ziekteregistratie</strong>
                            <p>
                              Werknemers kunnen zich ziek en beter melden. Eventueel kun je de loonstroken automatisch laten bijwerken aan de hand van een verzuimreglement.
                            </p>
                          </div>

                          <div className="col-4">
                            <strong>Wettelijk en bovenwettelijk</strong>
                            <p>
                              In Employes worden verlofaanvragen automatisch op de juiste wettelijke en bovenwettelijke reservering verwerkt.
                              {' '}
                              <strong>(Binnenkort beschikbaar)</strong>
                            </p>
                          </div>

                          <div className="col-4">
                            <strong>Vervallen van verlof</strong>
                            <p>
                              Je krijgt inzicht in hoeveel vakantie-uren er wanneer vervallen. Deze kun je eenvoudig afboeken.
                              {' '}
                              <strong>(Binnenkort beschikbaar)</strong>
                            </p>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>



                  <div className="koppeling-section" id="cat-4">
                    <div className="grid">
                      <div className="col-12">
                        <p className="eyebrow">100% digitaal verwerkt</p>

                        <h1 className="koppeling-heading-category">
                          Declaraties
                        </h1>

                        <p className="koppeling-heading-description">
                          Betrek werknemers bij je salarisadministratie en geef
                          ze inzicht in loonstroken en gewerkte uren.
                        </p>

                        <div className="grid">

                          {/* <div className="col-4">
                            <strong>Online toegang</strong>
                            <p>
                              Werknemers kunnen gratis een account aanmaken om
                              loonstroken en gewerkte uren te bekijken op onze
                              responsive webapp.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Documenten</strong>
                            <p>
                              De werkgever kan documenten delen met de
                              werknemer. Deze zijn zichtbaar in de
                              werknemersomgeving.
                            </p>
                          </div>
*/}
                        </div>


                        <div className="grid">

                        <div className="col-4">
                          <strong>Reiskosten declareren</strong>
                          <p>
                            Je werknemers kunnen eenvoudig een gemaakte reis invoeren. Employes rekent dan automatisch de reisafstand uit en bepaalt de hoogte van de vergoeding. <br />
                          </p>
                        </div>

                          <div className="col-4">
                            <strong>Bonnetjes declareren</strong>
                            <p>
                              Naast de reiskosten, kunnen werknemers ook andere gemaakte kosten declareren. Het is mogelijk om bijlages te uploaden.<br />
                            </p>
                          </div>



                          <div className="col-4">
                            <strong>BTW verwerken</strong>
                            <p>
                            Bij het controleren van de declaraties kun je als werkgever de te verrekenen btw invoeren.<br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="koppeling-section" id="cat-5">
                    <div className="grid">
                      <div className="col-12">
                        <p className="eyebrow">Overzichtelijk en uitgebreid</p>

                        <h1 className="koppeling-heading-category">
                          Rapporten
                        </h1>

                        <p className="koppeling-heading-description">
                          Een actueel inzicht in je salarisadministratie aan de
                          hand van overzichtelijke rapportages.
                        </p>

                        <div className="grid">

                          <div className="col-4">
                            <strong>Journaalposten</strong>
                            <p>
                              Selecteer een periode en krijg duidelijke
                              journaalposten voor de boekhouding eventueel uitgeplitst per afdeling.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Kostenoverzicht</strong>
                            <p>
                              Krijg een uitgebreid inzicht in de
                              kosten van je personeel.
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Gewerkte uren</strong>
                            <p>Bekijk hoeveel uur werknemers hebben gewerkt.</p>
                          </div>
                        </div>

                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>Verzuimrapportages</strong>
                            <p>
                              Overzicht van het aantal ziekte-uren per
                              medewerker.
                            </p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="koppeling-section" id="cat-6">
                    <div className="grid">
                      <div className="col-12">
                        <p className="eyebrow">Koppel Employes</p>

                        <h1 className="koppeling-heading-category">
                          Koppelingen
                        </h1>

                        <p className="koppeling-heading-description">
                          Bespaar tijd door Employes te koppelen aan meer handige
                          softwarepakketten en geef de accountant toegang.
                        </p>

                        <div className="grid">
                          <div className="col-4">
                            <strong>Boekhoudpakket</strong>
                            <p>
                              Exporteer je journaalposten met een druk op de
                              knop naar Moneybird, Exact, Twinfield, e-Boekhouden.nl,
                              Yuki, Reeleezee, Informer of Snelstart.
                              <br />
                              <Link
                                to="/product/koppelingen/"
                                className="link inline"
                              >
                                Bekijk boekhoudpakketten
                              </Link>
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Urenregistratie</strong>
                            <p>
                              Exporteer uren met een druk op de knop.
                              De volgende systemen koppelen met Employes:
                              Werktijden.nl, Keeping en Shiftbase.
                              <br />
                              <Link
                                to="/product/koppelingen/"
                                className="link inline"
                              >
                                Bekijk urensystemen
                              </Link>
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Toegang accountants</strong>
                            <p>
                              Employes heeft een speciale interface voor
                              accountants waarmee ze gratis kunnen meekijken in de
                              salarisadministratie.
                            </p>
                          </div>
                        </div>

                        <div className="grid margin-m-top">
                          <div className="col-4">
                            <strong>API</strong>
                            <p>
                              Employes biedt een API aan waarmee het mogelijk is
                              om zelf een integratie te maken.
                              <br />
                              <a
                                href="https://developer.employes.nl/"
                                className="link inline"
                                rel="nofollow"
                              >
                                Bekijk API-documentatie
                              </a>
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>iCal-integratie</strong>
                            <p>
                              Het is mogelijk om verlof te synchroniseren met
                              een externe agenda via iCal.{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default MogelijkhedenPage
